// Auth
export const AUTH_START = 'AUTH_START'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_FAIL = 'AUTH_FAIL'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH'

// Init
export const INIT_START = 'INIT_START'
export const INIT_SUCCESS = 'INIT_SUCCESS'
export const INIT_FAIL = 'INIT_FAIL'
import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
	menuData: [],
	error: null,
	loading: false,
	success: false
};

const initStart = (state, action) => {
	return updateObject(state, { error: null, loading: true, success: false });
};

const initSuccess = (state, action) => {
	return updateObject(state, {
		error: null,
		loading: false,
		success: true,
		menuData: [ ...action.data.schoolTypes ]
	});
};

const initFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false
	});
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.INIT_START:
			return initStart(state, action);
		case actionTypes.INIT_SUCCESS:
			return initSuccess(state, action);
		case actionTypes.INIT_FAIL:
			return initFail(state, action);
		default:
			return state;
	}
};

export default reducer;

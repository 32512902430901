import React from 'react'
import { Button } from 'reactstrap'

const LoginButton = (props) => {
	return (
		<React.Fragment>
			<Button className="btn-pill" color="success">
				{props.title}
			</Button>
		</React.Fragment>
	)
}

export default LoginButton

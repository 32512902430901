import { store } from '../index';

export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties
  };
};

export const PERM = {
  SHOW_ADMIN_SETTINGS: ['admin']
}

export const roles = {
  user: 'användare',
  admin: 'admin',
  teacher: 'lärare',
  customer: 'kund',
  student: 'student'
}

export const availableRoles = [
  { id: 'user', name: roles.user },
  { id: 'admin', name: roles.admin },
  { id: 'teacher', name: roles.teacher },
  { id: 'customer', name: roles.customer },
  { id: 'student', name: roles.student }
]

export const contentTags = {
  examination: 'examination',
  other: 'annat'
}

export const availableContentTags = [
  { id: 'examination', name: contentTags.examination },
  { id: 'other', name: contentTags.other }
]

export const subTypes = {
  schoolType: 'Skoltyp',
  subject: 'Ämne',
  course: 'Kurs',
  courseContent: 'Kursområde',
  lesson: 'Lektion'
}

export const isAuthed = (permList, userRoles) => {
  //console.log('CHECKING FOR USER userroles', userRoles, 'permList', permList)
  if (!userRoles) userRoles = store.getState().auth.roles
  const isOk = userRoles.some(r => permList.indexOf(r) >= 0)
  return isOk
} 
import React, { Fragment } from 'react'
import { useSelector } from 'react-redux'
import LoginButton from 'components/Header/LoginButton'
import { DropdownToggle, DropdownMenu, Col, Row, Button, UncontrolledButtonDropdown } from 'reactstrap'
import { NavLink } from 'react-router-dom'
import cx from 'classnames';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const UserBox = () => {
	const auth = useSelector((state) => state.auth)

	console.log('auth.userId', auth.userId)

	let loggedInText = 'Okänt inlogg'
	if (auth.token && auth.userId === 'skolon_anonymous_student') {
		loggedInText = 'Inloggad med Skolon.'
	} else if (auth.token && auth.userId === 'skolon_anonymous_teacher') {
		loggedInText = 'Inloggad med Skolon som lärare.'
	} else if (auth.token) {
		loggedInText = 'Inloggad som ' + auth.email
	}

	return (
		<Fragment>
			{auth.token ? (
				<UncontrolledButtonDropdown>
					<DropdownToggle caret className={cx('btn-icon btn-icon-only mr-0')} color="primary">
						<div className="btn-icon-wrapper">
							<div className="btn-icon-wrapper">
								<FontAwesomeIcon icon={faUser} />
							</div>
						</div>
					</DropdownToggle>

					<DropdownMenu right className="rm-pointers dropdown-menu-sm my-menu">
						<div className="grid-menu grid-menu-1col">

							<Row className="no-gutters">
								<Col sm="12">
									<p>
										{loggedInText}
									</p>
									<NavLink style={{ textDecoration: 'none' }} to="/auth/logout">
										<Button
											className="btn-icon-vertical btn-transition btn-transition-alt pt-2 pb-2"
											outline
											color="primary"
										>
											<i className="pe-7s-lock icon-gradient bg-amy-crisp btn-icon-wrapper mb-2">
												{' '}
											</i>
											Logga ut
										</Button>
									</NavLink>
								</Col>
							</Row>
						</div>
					</DropdownMenu>
				</UncontrolledButtonDropdown>
			) : (

				<NavLink to="/auth/login">
					<LoginButton title={'Logga in'} />
				</NavLink>
			)}
		</Fragment>
	)
}

export default UserBox

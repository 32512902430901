import React from 'react'
import { NavLink } from 'react-router-dom'
import { Nav } from 'reactstrap'

import PageButton from '../../../components/Header/PageButton'

const MegaMenu = () => {

	return (
		<React.Fragment>
			<Nav>
				<NavLink to="/about-us">
					<PageButton title={'Om oss'}/>
				</NavLink>
				<NavLink to="/how-it-works">
					<PageButton title={'Så funkar det'}/>
				</NavLink>
				<NavLink to="/contact">
					<PageButton title={'Kontakta oss'}/>
				</NavLink>
				<NavLink to="/teachers">
					<PageButton title={'Lärarteamet'}/>
				</NavLink>
				<NavLink to="/pricelist">
					<PageButton title={'Begär offert'}/>
				</NavLink>
			</Nav>
		</React.Fragment>
	)
}

export default MegaMenu

import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk'
import reducers from '../store/reducers';

export default function configureStore() {
  const composeEnhancers =
    (process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : null) || compose;

  return createStore(
    combineReducers({
      ...reducers
    }),
    composeEnhancers(applyMiddleware(thunk))
  );
}
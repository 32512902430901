import React, { useEffect, useCallback, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Main from 'pages/Main'
import * as authActions from 'store/actions/auth'
import { DragDropContext } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import CookieConsent from "react-cookie-consent"
import ReactGA from 'react-ga4'
import ReactPixel from 'react-facebook-pixel'
import Loader from 'react-loaders'
import { HelmetProvider } from 'react-helmet-async'

const usePageViews = () => {
	let location = useLocation()
	useEffect(
		() => {
			//ReactGA.pageview(location.pathname)
			ReactGA.send({ hitType: "pageview", page: location.pathname, title: location.pathname });

		},
		[location]
	)
}

const App = (props) => {
	const userId = useSelector((state) => state.auth.userId)
	const checkedForUser = useSelector((state) => state.auth.success || state.auth.error)

	const dispatch = useDispatch()
	usePageViews()

	const [reactGaInitialised, setReactGaInitialised] = useState(false)

	const initGa = useCallback(() => {
		if (!reactGaInitialised) {
			const isProd = window.location.hostname === "lektionsakuten.com"
			const trackingId = isProd ? "G-TCV639D7X7" : "G-L0HEYG0RE1"
			//const trackingId = "G-TCV639D7X7"
			const isDebug = !isProd
			ReactGA.initialize([
				{
					trackingId: trackingId,
					debug: isDebug,
					gaOptions: {
						userId: userId ? userId : 0
					}
				}
			])
			const page = window.location.pathname;
			//ReactGA.set({ page })
			//ReactGA.pageview(page)
			ReactGA.send({ hitType: "pageview", page: page, title: "Landning" });


			setReactGaInitialised(true)
		}
	}, [userId, reactGaInitialised])

	useEffect(
		() => {
			dispatch(authActions.authCheckState());
		},
		[dispatch]
	)

	useEffect(() => {
		if (checkedForUser) initGa()
	}, [checkedForUser, initGa])

	if (!checkedForUser) {
		return (<div className="loader-container">
			<div className="loader-container-inner">
				<div className="text-center">
					<Loader type="ball-pulse-rise" />
				</div>
				<h6 className="mt-5">Laddar sidan</h6>
			</div>
		</div>)
	}

	const helmetContext = {}

	const app = (
		<HelmetProvider context={helmetContext}>
			<React.Fragment>
				<Main />
				<CookieConsent
					location="bottom"
					buttonText="Jag förstår"
					cookieName="LACookiev3"
					expires={150}
				onAccept={ReactPixel.grantConsent}
				>
					Lektionsakuten använder cookies för att kunna streama innehåll och för anonym statistik.{" "}
				</CookieConsent>
			</React.Fragment>
		</HelmetProvider>
	);

	return app;
};

export default DragDropContext(HTML5Backend)(App);

import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
	token: null,
	userId: null,
	email: '',
	firstname: '',
	lastname: '',
	roles: [],
	error: null,
	loading: false,
	success: false,
	authRedirectPath: '/'
};

const authStart = (state, action) => {
	return updateObject(state, { error: null, loading: true, success: false });
};

const authSuccess = (state, action) => {
	const token = action.data.token;
	const user = action.data.user;
	return updateObject(state, {
		token: token,
		userId: user.id,
		email: user.personemail,
		firstname: user.firstname,
		lastname: user.lastname,
		roles: user.roles || user.roller__pc.split(';'),
		error: null,
		loading: false,
		success: true
	});
};

const authFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false
	});
};

const authLogout = (state, action) => {
	return updateObject(state, {
		token: null,
		userId: null,
		email: null,
		firstname: '',
		lastname: '',
		roles: [],
		success: true,
		error: null
	});
};

const setAuthRedirectPath = (state, action) => {
	return updateObject(state, { authRedirectPath: action.path });
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.AUTH_START:
			return authStart(state, action);
		case actionTypes.AUTH_SUCCESS:
			return authSuccess(state, action);
		case actionTypes.AUTH_FAIL:
			return authFail(state, action);
		case actionTypes.AUTH_LOGOUT:
			return authLogout(state, action);
		case actionTypes.SET_AUTH_REDIRECT_PATH:
			return setAuthRedirectPath(state, action);
		default:
			return state;
	}
};

export default reducer;

import jwt_decode from 'jwt-decode';
import { useHistory } from "react-router-dom";
import AuthService from 'services/auth';
import UserService from 'services/user';
import SubscriptionService from 'services/subscription'
import SchoolService from 'services/school'
import * as actionTypes from './actionTypes';

export const authStart = () => {
	return {
		type: actionTypes.AUTH_START
	};
};

export const authSuccess = (data) => {
	return {
		type: actionTypes.AUTH_SUCCESS,
		data,
	};
};

export const authFail = (error) => {
	return {
		type: actionTypes.AUTH_FAIL,
		error: error
	};
};

export const logout = () => {
	localStorage.removeItem('token');
	localStorage.removeItem('expirationDate');
	localStorage.removeItem('userID');
	return {
		type: actionTypes.AUTH_LOGOUT
	};
};

export const checkAuthTimeout = (expirationTime) => {
	return (dispatch) => {
		setTimeout(() => {
			dispatch(logout());
		}, expirationTime);
	};
};

const RedirectSub = async (subscriptions) => {
	const history = useHistory()
	if (subscriptions.length === 1) {
		const sub = await SubscriptionService.getSub(subscriptions[0])
		const schoolType = await SchoolService.getSchoolType(sub.content)
		history.push(`/${schoolType.param}`)
	}
}

export const login = (data) => {
	return async (dispatch) => {
		dispatch(authStart())
		try {
			const res = await AuthService.login(data);
			await loginFrontEnd(res, dispatch)
		} catch (e) {
			dispatch(authFail(e.message))
		}
	}
}

export const loginSkolon = (res) => {
	return async (dispatch) => {
		dispatch(authStart())
		try {
			console.log('Logging in with skolon', res)
			await loginFrontEnd(res, dispatch)
		} catch (e) {
			dispatch(authFail(e.message))
		}
	}
}
const loginFrontEnd = async (res, dispatch) => {
	const user = res.user
	const token = res.token
	const subscriptions = res.user.subscriptions
	const decoded = jwt_decode(token)
	localStorage.setItem('token', token)
	const expirationDate = new Date(decoded.exp * 1000)
	const expireTime = expirationDate - new Date()
	localStorage.setItem('expirationDate', expirationDate.toString())
	localStorage.setItem('userID', user.id)
	dispatch(authSuccess(res))
	await RedirectSub(subscriptions)
	dispatch(checkAuthTimeout(expireTime))
}

export const authCheckState = () => {
	return async (dispatch) => {
		const token = localStorage.getItem('token')
		const userID = localStorage.getItem('userID')
		if (!token || !userID) {
			//console.log('checkstate: no token, no user id, logging out')
			dispatch(logout())
		} else {
			const expirationDate = new Date(localStorage.getItem('expirationDate'));
			if (expirationDate <= new Date()) {
				//console.log('checkstate: expired, logging out')
				dispatch(logout());
			} else {
				try {
					const res = await UserService.getUser(userID)
					console.log('checkstate got user res', res)
					const data = {
						token: token,
						user: res.record
					}
					await dispatch(authSuccess(data));
					const expireTime = expirationDate - new Date()
					dispatch(checkAuthTimeout(expireTime))
				} catch (e) {
					console.error('checkstate: error, logging out', e)
					dispatch(logout());
				}
			}
		}
	};
};

import React from 'react'
import { connect } from 'react-redux'
import { NavLink, useRouteMatch } from 'react-router-dom'
import AppMobileMenu from '../../Layout/AppMobileMenu'
import {
	setEnableClosedSidebar,
	setEnableMobileMenu,
	setEnableMobileMenuSmall
} from '../../store/reducers/ThemeOptions'

class HeaderLogo extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			active: false,
			mobile: false,
			activeSecondaryMenuMobile: false,
		};
	}

	toggleEnableClosedSidebar = () => {
		let { enableClosedSidebar, setEnableClosedSidebar } = this.props;
		setEnableClosedSidebar(!enableClosedSidebar);
	};

	currentPath = () => {
		const { path } = useRouteMatch()
		return path
	}

	state = {
		openLeft: false,
		openRight: false,
		relativeWidth: false,
		width: 280,
		noTouchOpen: false,
		noTouchClose: false
	};

	render() {

		return (
			<React.Fragment>
				<div className="app-header__logo">
					<NavLink to="/">
						<div className="logo-src"/>
					</NavLink>
				</div>
				<AppMobileMenu />
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
	enableMobileMenu: state.ThemeOptions.enableMobileMenu,
	enableMobileMenuSmall: state.ThemeOptions.enableMobileMenuSmall
});

const mapDispatchToProps = (dispatch) => ({
	setEnableClosedSidebar: (enable) => dispatch(setEnableClosedSidebar(enable)),
	setEnableMobileMenu: (enable) => dispatch(setEnableMobileMenu(enable)),
	setEnableMobileMenuSmall: (enable) => dispatch(setEnableMobileMenuSmall(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(HeaderLogo);

import axios from 'axios';
import authHeader from 'shared/authHeader';

class SubscriptionService {

    static getSub(subID) {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_URL}/api/subscription/${subID}`, {
                    headers: authHeader()
                });
                resolve(res.data)
            } catch (e) {
                reject(e)
            }
        })
    }

}

export default SubscriptionService;

import axios from 'axios';

class AuthService {
	static login(data) {
		return new Promise(async (resolve, reject) => {
			try {
				const res = await axios.post(`${process.env.REACT_APP_URL}/api/auth/login`, data);
				resolve(res.data);
			} catch (e) {
				reject(e);
			}
		});
	}

	static skolonRedirect(code) {
		return new Promise(async (resolve, reject) => {
			try {
				const res = await axios.get(`${process.env.REACT_APP_URL}/api/skolon/skolon-redirect?code=${code}`);
				resolve(res.data);
			} catch (e) {
				resolve({message: 'Misslyckades'})
			}
		});
	}
}

export default AuthService;

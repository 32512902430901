import React, { useState, useEffect } from 'react'
import { Button } from 'reactstrap'
import { useLocation } from 'react-router-dom'

const getPageCompare = (pathname, title) => {
	const currentPage = {
	   "/about-us": "Om oss",
	   "/how-it-works": "Så funkar det",
	   "/contact": "Kontakta oss",
	   "/teachers": "Lärarteamet",
		"/pricelist": "Begär offert"
	 }
	 return currentPage[pathname] === title
}

const PageButton = (props) => {
	const [isActive, setIsActive] = useState(false)
	const location = useLocation()

	useEffect(() => {
		setIsActive(getPageCompare(location.pathname, props.title))
	}, [location.pathname, props.title])

	return (
		<React.Fragment>
			<Button style={{marginRight: '20px', color: 'white'}} active={isActive}>
				{props.title}
			</Button>
		</React.Fragment>
	)
}

export default PageButton
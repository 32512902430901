import axios from 'axios'
import authHeader from 'shared/authHeader'

class SchoolService {
	
	static getSchoolTypeData(schoolTypeId) {
		return new Promise(async (resolve, reject) => {
			try {
				const res = await axios.get(`${process.env.REACT_APP_URL}/api/school/schoolData/schoolTypeByIdentifier/${schoolTypeId}`, {
					headers: authHeader()
				})
				resolve(res.data)
			} catch (e) {
				reject(e)
			}
		});
	}

	static getSchoolType(schoolTypeId) {
		return new Promise(async (resolve, reject) => {
			try {
				const res = await axios.get(`${process.env.REACT_APP_URL}/api/school/schoolType/${schoolTypeId}`, {
					headers: authHeader()
				})
				resolve(res.data)
			} catch (e) {
				reject(e)
			}
		});
	}

	static getLessonsByCourseContent(courseContentID) {
		return new Promise(async (reslove, reject) => {
			try {
				const res = await axios.get(`${process.env.REACT_APP_URL}/api/school/lessons/${courseContentID}`, {
					headers: authHeader()
				})
				reslove(res.data)
			} catch (e) {
				reject(e)
			}
		});
	}

	static getLessonsByTeacher(teacherID) {
		return new Promise(async (reslove, reject) => {
			try {
				const res = await axios.get(`${process.env.REACT_APP_URL}/api/school/lesson/teacher/${teacherID}`, {
					headers: authHeader()
				})
				reslove(res.data)
			} catch (e) {
				reject(e)
			}
		});
	}

	static getAssignmentsByLesson(lessonID) {
		return new Promise(async (reslove, reject) => {
			try {
				const res = await axios.get(`${process.env.REACT_APP_URL}/api/school/assignments/${lessonID}`, {
					headers: authHeader()
				})
				reslove(res.data)
			} catch (e) {
				reject(e)
			}
		});
	}

	static getTeachers() {
		return new Promise(async (reslove, reject) => {
			try {
				const res = await axios.get(`${process.env.REACT_APP_URL}/api/school/teacher`, {
					headers: authHeader()
				})
				reslove(res.data)
			} catch (e) {
				reject(e)
			}
		});
	}

	static getTeacherByID(id) {
		return new Promise(async (reslove, reject) => {
			try {
				const res = await axios.get(`${process.env.REACT_APP_URL}/api/school/teacher/${id}`, {
					headers: authHeader()
				})
				reslove(res.data)
			} catch (e) {
				reject(e)
			}
		});
	}

	static getCourseContentPath(id) {
		return new Promise(async (reslove, reject) => {
			try {
				const res = await axios.get(`${process.env.REACT_APP_URL}/api/school/courseContent/path/${id}`, {
					headers: authHeader()
				})
				reslove(res.data)
			} catch (e) {
				reject(e)
			}
		});
	}

	static getCourse(id) {
		return new Promise(async (reslove, reject) => {
			try {
				const res = await axios.get(`${process.env.REACT_APP_URL}/api/school/course/${id}`, {
					headers: authHeader()
				})
				reslove(res.data)
			} catch (e) {
				reject(e)
			}
		});
	}

	static getCourseContent(id) {
		return new Promise(async (reslove, reject) => {
			try {
				const res = await axios.get(`${process.env.REACT_APP_URL}/api/school/courseContent/${id}`, {
					headers: authHeader()
				})
				reslove(res.data)
			} catch (e) {
				reject(e)
			}
		});
	}

	static getLesson(id) {
		return new Promise(async (reslove, reject) => {
			try {
				const res = await axios.get(`${process.env.REACT_APP_URL}/api/school/lesson/${id}`, {
					headers: authHeader()
				})
				reslove(res.data)
			} catch (e) {
				reject(e)
			}
		});
	}

	static getAssignment(id) {
		return new Promise(async (resolve, reject) => {
			try {
				const res = await axios.get(`${process.env.REACT_APP_URL}/api/school/assignment/${id}`, {
					headers: authHeader()
				})
				resolve(res.data)
			} catch (e) {
				reject(e)
			}
		});
	}

	static getTeacher(id) {
		return new Promise(async (reslove, reject) => {
			try {
				const res = await axios.get(`${process.env.REACT_APP_URL}/api/school/teacher/${id}`, {
					headers: authHeader()
				})
				reslove(res.data)
			} catch (e) {
				reject(e)
			}
		})
	}

}

export default SchoolService;
